import React from "react";
import { Button, Image, Select } from "semantic-ui-react";
import { GameTitle } from "./components/GameTitle.jsx";
import { states } from "./states.js";
import "./App.css";

const DEFAULT_GAME_TITLE = "Game Title / 💖 Rachel";
const LOGO_SRC = "./logo-no-background.png";
const CLAIMS_KEY = "claims";
const TITLE_KEY = "title";

function App() {
  const [title, setTitle] = React.useState("");
  const [stateOptions, setStateOptions] = React.useState(states);
  const [value, setValue] = React.useState("");
  const [claims, setClaims] = React.useState([]);

  // Set claims and options from local storage. Set title.
  React.useEffect(() => {
    const storedClaims = JSON.parse(localStorage.getItem(CLAIMS_KEY));
    if (storedClaims) {
      setClaims(storedClaims);
      removeOptions(storedClaims);
    }
    const storedTitle = localStorage.getItem(TITLE_KEY);
    setTitle(storedTitle ? storedTitle : DEFAULT_GAME_TITLE);
    // eslint-disable-next-line
  }, []);

  /**
   * removeOptions. Takes an array of state values and removes it stateOptions
   * @param {array[string]} claims
   */
  const removeOptions = (claims) => {
    const updatedOptions = [...stateOptions];

    claims.forEach((claim) => {
      const index = updatedOptions.findIndex((state) => state.value === claim);
      updatedOptions.splice(index, 1);
    });

    setStateOptions(updatedOptions);
  };

  const updateClaims = (e, data) => {
    const updatedClaims = [...claims, data.value];
    setClaims(updatedClaims);
    localStorage.setItem(CLAIMS_KEY, JSON.stringify(updatedClaims));
    removeOptions([data.value]);
  };

  const restartGame = (e) => {
    setClaims([]);
    localStorage.removeItem(CLAIMS_KEY);
    localStorage.setItem(TITLE_KEY, DEFAULT_GAME_TITLE);
    setTitle(DEFAULT_GAME_TITLE);
    setStateOptions(states);
  };

  const handleSetTitle = (title) => {
    localStorage.setItem(TITLE_KEY, title);
    setTitle(title);
  };

  return (
    <>
      <div className="app">
        <div className="console container">
          <div id="intro" className="container mat">
            <Image
              className="mat"
              alt="licenseplate.quest logo"
              src={LOGO_SRC}
            />
            <GameTitle title={title} setTitle={handleSetTitle} />
          </div>
          <hr width="100%" />
          <div id="gameplay" className="container mat">
            <Select
              search
              placeholder="Select US State..."
              options={stateOptions}
              selectOnNavigation={false}
              onChange={updateClaims}
              value={value}
              onClose={() => setValue("")}
            />
            <div className="mat">
              {claims?.length} Found. {stateOptions?.length} Remain.
            </div>
            <div className="mb2" style={{textAlign: "center"}}>
              {claims?.map((c) => (
                <div
                  style={{ fontSize: "1.5rem", marginBottom: ".5rem" }}
                  key={c}
                >
                  {c}
                </div>
              ))}
            </div>
            <Button onClick={restartGame}>Restart Game</Button>
          </div>
          <hr width="100%" />
          <div id="dedication" className="container mat">
            <div
              className="item mb2"
              style={{
                flexWrap: "wrap",
                flexDirection: "row",
                justifyContent: "center",
                padding: 5,
              }}
            >
              <img
                alt="Young Rachel at Neshkoro"
                src="small-rachel.png"
                style={{ padding: 5, maxWidth: 180 }}
              ></img>
              <img
                alt="A bit older Rachel at Neshkoro"
                src="big-rachel.jpeg"
                style={{ padding: 5, maxWidth: 180 }}
              ></img>
            </div>
            <div className="mb2" style={{ textAlign: "center" }}>
              Dedicated to Rachel Amelia Lerman on her 32nd birthday. May she
              collect all the states, win all the cases, and live/love life to
              the fullest. Love, James Ilana and Emunah.
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
