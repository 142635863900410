import React from "react";
import { Form, Icon } from "semantic-ui-react";

export function GameTitle({ title, setTitle }) {
  const [editing, setEditing] = React.useState(false);
  const [editedTitle, setEditedTitle] = React.useState(title);

  React.useEffect(() => {
    setEditedTitle(title);
  }, [title]);

  const saveOnEnter = (e) => {
    if (e.key === "Enter") {
      setEditing(false);
      setTitle(e.target.value);
    }
  };

  const editState = (
    <Form>
      <Form.Input
        size="huge"
        value={editedTitle}
        onChange={(e) => setEditedTitle(e.target.value)}
        onKeyDown={saveOnEnter}
      />
    </Form>
  );

  const displayState = (
    <>
      <div style={{ fontSize: "1.75rem", lineHeight: '2rem', width: '100%' }}>{title}</div>
      <Icon
        link
        name="pencil"
        onClick={() => setEditing(true)}
        style={{ padding: 5 }}
      />
    </>
  );

  return (
    <div
      className="item mat"
      style={{ justifyContent: "center", alignContent: "center", width: '100%' }}
    >
      {editing ? editState : displayState}
    </div>
    );
}
